@import url('https://fonts.googleapis.com/css2?family=League+Script&family=Roboto:ital,wght@0,400;0,500;0,900;1,500&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --box-shadow-lg: 0 5px 31px rgba(21, 32, 38, 0.07),
    0 23px 24px -18px rgba(21, 32, 38, 0.27);

  --color-medium: #393939;
  --color-soft-dark: #282828;
  --color-dark: #141414;
  --color-orange: #eb9632;

  --indent-spacing: 40px;
}

@media (max-width: 570px) {
  :root {
    --indent-spacing: 20px;
  }
}

@media (max-width: 400px) {
  :root {
    --indent-spacing: 15px;
  }
}

